import React, {Component} from 'react';
import {connect} from '../service.js'

import Button from '@mui/material/Button';

class Ahs extends Component {

    render () {
    	return (
    		<div style={{display: 'grid', padding: '25px'}}>
    			{/*<button className="add-button1">Установить ...</button>*/}
                <Button className="add-button" variant="contained">Установить ...</Button>
    		</div>
    	)
    }

    componentDidMount() { 
    	console.log("connecting ...");
    	connect();
        setTimeout(() => {
            this.forceUpdate(()=>{
                console.log("Update...")
            })
            var item = document.querySelector('.add-button');
            // console.log(item.style.display);
            console.log(item);
            if (item.style.display === 'none') {
                window.location.reload() //актуально после проверки появления кнопочки
            }
        }, 1000);


    }
}

export default Ahs