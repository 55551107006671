import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

// import { useNavigate, useLocation } from "react-router-dom";
// const navigate = useNavigate();

  var DS = {

    data:{
      'inputN':'',
      'inputP':'',
      'inputA':'',
      'm1':false,
      'm2':false,
      'm3':false,
      'm4':false,
      'm5':false           
    }
  }

  var DS_q = {}

  var getDs_q = () => {
      return DS_q;
    }

  var setDs_q = (s) => {
      DS_q = s;
  }

  var getDs = () => {
    return DS
  }

  var setDs = (s) => {
    // setDs({'key':e.target.id, 'val':e.target.value})
      // DS.data.f = s
      DS.data[s.key] = s.val
      // console.log(s)
      // console.log(s.keys())
  }

function fetchDs(DS_items) {

  let config = dotenv.config();
  // console.log(process.env)
  dotenvExpand(config);
  // console.log(process.env);
  // console.log(api_end_pint)

  var api_end_pint = process.env.REACT_APP_API_END_POINT;
  postData(api_end_pint, DS_items)
  // postData('http://127.0.0.1/voroneg/sendr.php')
    .then((response) => {
      console.log("-1:"+ response)
      // console.log(response.text())
      return response.json()
    })
    .then((data) => {
      console.log("-2:"+ data.result) // JSON data parsed by `response.json()` call
      if (data.result === 'OK') {
        // window.open('/#/ok','_self');
        window.location.href = '/#/ok';
        // navigate('/ok/'); // убрал отсюда, все нужно перенести к омпоненты
      }
      return (data.result)
    })
    .catch ((error) => {
      console.log("-e:"+ error)
    })

}


async function postData(url = '', DS_items) {
  // Default options are marked with *
  // console.log(url);
  console.log(DS_items);

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit, !!! same-origin !!! /// omit
    credentials: 'omit',
    // убрал, что бы была возможнсоть отправлять от все сайтов (видимо с мобильного и локального не очень хорошо работает)
    headers: {
      // 'Content-Type': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    // referrer: "",
    referrerPolicy: 'no-referrer', // no-referrer, *client, npm run-script build
    // body: "data="+JSON.stringify(DS.data) // body data type must match "Content-Type" header
    body: "data="+JSON.stringify(DS_items) // body data type must match "Content-Type" header    
  });
  console.log(response)
  return await response
}


export {getDs, setDs, fetchDs, getDs_q, setDs_q}

// var dotenv = require('dotenv')
// var dotenvExpand = require('dotenv-expand')

// var myEnv = dotenv.config()
// dotenvExpand(myEnv)