// нормально работает history + настройка анамации для всего рендера
// уюрать лишнее и запилить бесконечный список (возможно состояние, для понимания структуры)
// https://habr.com/ru/post/429712/
// задержка по веремни для перехода в корзину
// переход по маршрутам https://webformyself.com/perexod-na-react-router-v6-polnoe-rukovodstvo/

import React, { useState, useEffect } from 'react';
// import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// import MenuIcon from '@mui/icons-material/Menu';
import FolderIcon from '@mui/icons-material/Folder';

import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import { styled, alpha } from '@mui/material/styles';
// import { InputBase } from '@mui/material';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import { green } from '@mui/material/colors';

import { useParams } from "react-router-dom";

// import Fade from '@mui/material/Fade';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

import { CircularProgress } from '@mui/material';

//
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
//

import { useNavigate, useLocation } from "react-router-dom";

import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'

const drawerWidth = 300; //240

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // fdn 13-12-21
   display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  // fdn 13-12-21

  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  menuButtonS: {
//    marginRight: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: '0px'
  },
  
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  // drawerPaper: {
  //   width: drawerWidth,
  // },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3), // паддинги для основного скроллинга
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
    marginTop: '56px', //30px
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // justifyContent: "center",    
  },

  mainfixmenuButton: {
        marginRight: '-12px',
        // display: 'none',
  },

  inline: {
    display: 'inline',
  },

  rootlist: {
    width: '100%',
    maxWidth: '36ch',
    // backgroundColor: theme.palette.background.paper,
  },

//  imgItem: {
//   top: 0,
//   left: 0,
//   bottom: 0,
//   right: 0,
//   maxHeight: '100%',
//   maxWidth: '100%',
//   display: 'block',
//   margin: 'auto',
// },

}));

let config = dotenv.config()
// console.log(process.env)
dotenvExpand(config)


export default function PersistentDrawerLeft(props) {
  
  let navigate = useNavigate();
  const classes = useStyles();
  // const theme = useTheme();
  let location = useLocation();
  const [checked, setChecked] = React.useState(false);
  // const navigate = useNavigate();

  let { id } = useParams();
  // const [open, setOpen] = useState(false);
  const open = false;
  const [state, setState] = useState({});
  const [groupNow, setGroupNow] = useState(id);

// Route::get('/v0/group/{id}', 'ContentController@getgroupid');
// Route::get('/v0/item/{id}', 'ContentController@getitemid');
// Route::get('/v0/home', 'ContentController@gethome');

  // let config = dotenv.config()
  // console.log(process.env)
  // dotenvExpand(config)
  var api_end_pint = process.env.REACT_APP_API_END_POINT_GAZ69;
  var web_root_site = process.env.REACT_APP_WEB_ROOT_SITE;
  
  const loadDataAPI = (ids) => {
    // api_end_pint + "group/"+ids"
    // fetch("http://api.gaz69.net/api/v0/group/"+ids)
    var local_end = '';
    
    // console.log(props);

    // console.log(api_end_pint);
    // console.log(web_root_site);

    // if (props.match.path === '/shop/:id') {

    if (location.pathname.substring(0, 6) === '/shop/') {  
      local_end = 'group/';
    } else {
      local_end = 'search/';
    }

    // console.log(local_end);
    // console.log(props);
    // console.log(location);

    // replace
    // ids = ids.replace(" ", "%20"); 
    var ids_url = encodeURI(api_end_pint+local_end+ids); //api_end_pint+local_end+ids

    // fetch(api_end_pint+"group/"+ids, {
    fetch(ids_url, {
      mode: 'cors',
      credentials: 'same-origin', // same-origin
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      setState(data);
    });
    console.log(ids_url); //api_end_pint+local_end+ids
  }

  if (id !== groupNow) {
    setGroupNow(id);
    loadDataAPI(id);
    console.log("fetching ..."+id);
  }

  useEffect (()=> {
    loadDataAPI(id);
    console.log("Hook fetching ef..."+id);
  }, [ id ]);

  // console.log(props.location);
  // console.log(props.match.path);
  // console.log(props);
  // console.log(state);


  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const isEmpty = function (obj) {
      for(var key in obj)
      {
          return false;
      }
      return true;
  }

  const backMain = () => {
    // History.push('/home');
    // props.history.push('/home');
    props.history.back();
    // alert("999");
    // console.log(props);
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
    // refin.current.focus();
    // устанвока фокуса для элемента
    // document.getElementById('fullWidthFieldSearch').focus();
  };

  const clickSearch = (e) => {
    // History.push('/home');
    // props.history.push('/home');
    // props.history.goBack();
    // alert("999");
    // props.history.push('/search/'+value.target.value);
    // исклчить пустой поиск + обнулить state.data перед поиском (это запустить прогрессор)
    // let v = e.target.value; !!!
    // console.log(e);
    // console.log(v);!!!
    // if ((v != '') && (v != undefined)) { !!!
    if ((e !== '') && (e !== undefined)) {
      // запускает прогрессор
      setState({}); 
      //
      // props.history.push('/search/'+v); //!!!
      // navigate('/search/'+v);
      navigate('/search/'+e);
    }
    // console.log("+search");

  }  
  
// необходимо подрезать длинну названиения товара, что бы исключить переносы
// в противном случае расползается сетка
  const cropName = (s) => {
    return (s.length > 50 ? s.substring(0,50)+"...": s);
  }

// формироване группы в каталоге
// props.item.id +"#"
  let GItemRow = (props) => {
    // првоерить на props.item.URL
      return(
        <ListItem component="a" href={"/#/shop/"+props.item.id} dense={true}>
          { isEmpty(props.item.URL) === false &&
          <ListItemAvatar>
            <Avatar src={web_root_site+"/"+props.item.URL}>
              {/*
              <FolderIcon />
              */}
            </Avatar>
          </ListItemAvatar>
          }
          { isEmpty(props.item.URL) === true &&
          <ListItemAvatar>
            <Avatar>
              <FolderIcon fontSize="large"/>
            </Avatar>
          </ListItemAvatar>
          }          
          <ListItemText
          primary={props.item.pagetitle}
          />
        </ListItem>      
      )
  } 
// go Item
  const ClickGoItem = function (id) {
    console.log("goto -> "+id);
    // props.history.push("/#/item/"+id);
    navigate("/item/"+id);
    // props.history.go("/#/item/"+id);
  }
// concantItemInCart (copy from item !!!)
// объединение одинаковых позиций по корзине (тут надо срочно доделывать)
  const concantItemInCart = (cart) => {
    // console.log("@");
    // console.log(cart);
    const newcart = cart.reduce((acc, element) => { 

      var s = acc.findIndex((item) => item.id === element.id);
      s !== 0 ? acc.push(element) : acc[s].count += 1;
      return acc;

    }, []);
    // var newcart = cart;
    // console.log(newcart);
    return (newcart);
  }
  //
// add in cart (copy from item !!!)
  const Add = function (p) {
    // в Р весь полный item
    p['count'] = 1;
    // console.log(p);
    // var { count, CartItems } = props.pstate;
    var { CartItems } = props.pstate;
    // count +=1;
    CartItems.push(p);
    //объединяем позиции в корзине
    const NCartItems = concantItemInCart(CartItems);
    // console.log(props.pstate);
    // const NCartItems = CartItems;

    const Ncount = NCartItems.length;
    props.onSet({...props.pstate, count:Ncount, CartItems:NCartItems});
    // var k = props.onSet(props.pstate.addToCart(p));
  }

// add to cart and go-to Cart
  const CliskAddToCartAndGoToCart = function (item) {
    // console.log(props.pstate);
    // console.log(item);
    Add(item);
    // setTimeout (()=>{
    //   props.history.push("/cart");
    // },500);
    // props.history.push("/cart"); // для отладки корзины
    navigate("/cart");
    // console.log(props);    
  }

// формиование строки с товаром (добавить возможнсоть сразу занести в корзину)
  let ItemRow = (props) => {
// props.item.id +"#"+
      var img = props.item.image;
      (props.item.image == null) ? (img = web_root_site+props.nullImg) : img = web_root_site+props.item.image;
      return(
        <ListItem component="div" dense={true}>
            {/*
            href={"/#/item/"+props.item.id}
            <p> {props.item.id} | {props.item.pagetitle}</p>
            */}
            <ListItemAvatar onClick = {() => {ClickGoItem(props.item.id)}}>
              <Avatar style={{backgroundColor: "white"}}>
                <img src={img} alt='' className="list__items__image"/>
              </Avatar>
            </ListItemAvatar> 
            <ListItemText style={{whiteSpace: "pre-line"}} onClick = {() => {ClickGoItem(props.item.id)}} primary = {props.item.price+" руб."} secondary={ cropName(props.item.pagetitle) }/>
            <ListItemIcon style={{minWidth: '40px'}}>
              <AddShoppingCartIcon onClick = {() => {CliskAddToCartAndGoToCart(props.item)}}/>
            </ListItemIcon>
        </ListItem> 
      )
  } 

// search

// search
  
  // const refin = React.createRef();

  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <HideOnScroll {...props}>
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={backMain}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <ChevronLeftIcon />
            </IconButton>

            <Typography variant="h6" noWrap style={{ width: '60%', textAlign: 'center'}}>
              Запчасти
            </Typography>
{/*
            <Search>
              <SearchIconWrapper>
                <SearchIcon/>
              </SearchIconWrapper>
            </Search>
*/}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              className={clsx(classes.mainfixmenuButton, open && classes.hide)}
              onClick={handleChange}
            >
            {/*<SearchIcon/>            */}
            {/*{ checked === false ? <SearchIcon onClick={handleChange} /> : <CloseIcon style={{ color: green[500] }} onClick={handleChange}/> }*/}
            { checked === false ? <SearchIcon /> : <CloseIcon style={{ color: green[500] }}/> }
            </IconButton>
{/* */}

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              href = "/#/cart"
              className={clsx(classes.mainfixmenuButton, open && classes.hide)}
            >
              
              { props.pstate.count === 0 ? <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart/> </Badge>: <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart style={{ color: green[500] }}/> </Badge>}            
            
            </IconButton>
        </Toolbar>

      </AppBar>
      </HideOnScroll>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        
        {/*

        <div className={classes.drawerHeader} />

        <div>
          <h3>ID: {id}</h3>
        </div>
        */}
{/*Search*/}
{/*onClick = {(e) => {clickSearch(e)}} */}
        <Slide direction="down" in={checked} mountOnEnter unmountOnExit>        
                <Box
                  sx={{
                    // width: 500,
                    maxWidth: '100%',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    marginBottom: '10px',
                    paddingLeft: '8px',
                  }}
                >
                  <TextField fullWidth id="fullWidthFieldSearch" 
                  // ref={refin}
                  autoFocus
                  />
                    <IconButton 
                      type="submit"
                      onClick={(e) => {
                        let el = document.getElementById('fullWidthFieldSearch');
                        clickSearch(el.value);
                        console.log("searching... " + el.value);
                      }} 
                    >
                      <SearchIcon />
                    </IconButton>                
                </Box>
        </Slide>
 
        
        { isEmpty(state.data) === false &&
            
          <div className="main__list">
{/* группы */}  
            <List className={classes.rootlist}>
              {state.data.group.map( (item, index) => (
                <GItemRow key={item.id} item={item}></GItemRow>
              ))}

            </List>
{/*  отдельные товары  */}           
            <List>
              {state.data.items.map( (item, index) => (
                <ItemRow key={item.id} item={item} nullImg={state.data.nullimg}></ItemRow>
              ))}
            </List>

          </div>
        }

        { isEmpty(state.data) === true &&
          <div style={{textAlign: "center",  marginTop: "150px"}}>
            <CircularProgress />
          </div>
        }

      </main>
    </div>
    </Effect>
  );
}
