// import React from 'react';
// https://habr.com/ru/company/kts/blog/598835/
// https://reactrouter.com/docs/en/v6/api#useresolvedpath
// разбор роутера 6 версии
// https://webformyself.com/perexod-na-react-router-v6-polnoe-rukovodstvo/

// import React, { useState} from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
// import App from './App';
// import Ahs from 'components/Ahs';
import theme from './theme';
// import {connect} from './service.js'
// HashRouter as Router

// https://stackoverflow.com/questions/63124161/attempted-import-error-switch-is-not-exported-from-react-router-dom
// Redirect Navigate
// Switch Routes
import {HashRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';


// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'; // add
// import RaisedButton from 'material-ui/RaisedButton'; // add
// import getMuiTheme from 'material-ui/styles/getMuiTheme';
// import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';


import OkFill from 'components/ok.jsx';
import Install from 'components/install.jsx';
import Mainform from './page/mainform.jsx';
import Shop from './page/shop.jsx';
import Shops from './page/shops.jsx';
import Auto from './page/auto.jsx';
import Autoitem from './page/autoitem.jsx';
import Service from './page/service.jsx';
import Action from './page/action.jsx';
import Cart from './page/cart.jsx';
import Item from './page/item.jsx';
import Makequestion from './page/makequestion.jsx';
import About from './page/about.jsx';

// import Test from './page/_test.jsx';

import {initState} from './rootState.js';


// const {Component} = React;
const history = createBrowserHistory();
//HashRouter
//BrowserRouter

class Central extends React.Component{

  constructor(props) {
    super(props);
    this.state = initState;
  }

  SaveLocalStorage(data) {
    let data_to_save = JSON.stringify(data);
    window.localStorage.setItem('data_gaz69', data_to_save);
  }

  LoadLocalStorage() {
    // let data_string = window.localStorage.getItem('data_gaz69');
    let newState = JSON.parse(window.localStorage.getItem('data_gaz69'));
    // console.log(newState);
    this.setState(newState);
  } 

  componentDidMount() {
    this.LoadLocalStorage();
  } 

  superSetState(newState) {
    this.setState({...newState});
    // console.log(newState);
    //this.setState({...this., count:Ncount, CartItems:NCartItems});
    // this.setState(prevState => ({
      // isToggleOn: !prevState.isToggleOn
    // }));
    this.SaveLocalStorage(newState);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router history={history}>
      {/*<Ahs />
        <Router history={history}> */}
        <Routes>
          {/* */}
           <Route path="/" element={<Mainform />} />
           <Route path="/home" element={<Mainform />} />
           <Route path="/ok" element={<OkFill history={history} />} />
           <Route path="/about" element={<About history={history} />} />
           <Route path="/auto" element={<Auto history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} /> 
           <Route path="/autoitem/:id" element={<Autoitem history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} />           
           <Route path="/service" element={<Service history={history} />} /> 
           <Route path="/shops" element={<Shops history={history} pstate={this.state}/>} /> 
           <Route path="/shop/:id" element={<Shop history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} />
           
           <Route path="/item/:id" element={<Item history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} />
           <Route path="/cart" element={<Cart history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} />
           <Route path="/install" element={<Install />} /> 
           <Route path="/question" element={<Makequestion history={history} pstate={this.state} />} />
           <Route path="/action" element={<Action history={history} />} />
           <Route path="/search/:id" element={<Shop history={history} pstate={this.state} onSet={this.superSetState.bind(this)} />} />
        </Routes>
        {/*
        </Router> 
        <Ahs />*/}
      </Router>
      </ThemeProvider>
    );
  }

}

ReactDOM.render(
   
  <Central />,
  
  document.querySelector('#root'),
);

console.log ("Render index ...");


