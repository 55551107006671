import React, {Component} from 'react';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';

import Ahs from 'components/Ahs';

class Install extends Component {

    // constructor (props) {
    	
		  // super(props)

    // }
    
     render () {

      return (
   	<Container maxWidth="sm" style={{paddingTop: '20px'}}>
		<Typography variant="h4" component="h4" align="center">
				Для установки приложения, нажмите кнопку
		
    <Ahs />

    </Typography>
    
		</Container>
      );  

    }
}

export default Install
