// 1	Машина
// 2	Описание
// 3	Модификация
// 4	Двигатель
// 5	Дизайн
// 6	Системы помощи водителю
// 7	Технические характеристики
// 8	Клиентские программы
// https://gaztverskoy.ru/models/gazelle-nn/

// Газель NN
// Газель NEXT
// Газель CITY
// Газель Бизнес
// Валдай NEXT
// Газон NEXT
// Соболь 4 на 4
// Садко NEXT


// убираем Дизайн!!!

// import dotenv from 'dotenv'
// import dotenvExpand from 'dotenv-expand'

// const m09 = `A paragraph with *emphasis* and **strong importance**.

// > A block quote with ~strikethrough~ and a URL: https://reactjs.org.

// * Lists
// * [ ] todo
// * [x] done

// A table:

// | a | b |
// | - | - |
// `

const m01 = "Особенности"
const m02 = "Двигатель"

// const m02 = "Модификация"
// const m03 = "Двигатель/Преимущества"
// const m04 = "Дизайн"
// const m05 = "Системы помощи водителю"
// const m06 = "Технические характеристики"
// const m07 = "Клиентские программы" 

const t11 = `* НОВАЯ 6 МКПП
* МОДЕРНИЗИРОВАННАЯ ТРАНСМИССИЯ:
* НОВЫЕ АКТИВНЫЕ СИСТЕМЫ БЕЗОПАСНОСТИ: ABS, ESP
* НОВОЕ ОСВЕЩЕНИЕ
* СИСТЕМА КОНТРОЛЯ СЛЕПЫХ ЗОН
* СИСТЕМА КРУГОВОГО ОБЗОРА (SVS) И КАМЕРА ЗАДНЕГО ВИДА
* ЭЛЕКТРОМЕХАНИЧЕСКИЙ СТОЯНОЧНЫЙ ТОРМОЗ
* ПОДРЕССОРЕННОЕ СИДЕНЬЕ ВОДИТЕЛЯ
* БЕСКЛЮЧЕВОЙ ДОСТУП
* БЕСКЛЮЧЕВОЙ ЗАПУСК ДВИГАТЕЛЯ
* УСИЛЕННАЯ ШУМОВИБРОИЗОЛЯЦИЯ КАБИНЫ
* СИСТЕМА КЛИМАТ-КОНТРОЛЯ`

const t12 = `Дизельный Cummins ISF 2.8
Модернизированный EVOTECH PRO`

// const t13 = `* Эффективность. Новая 6-ступенчатая МКПП'
// * Безопасность. Более 20 современных систем безопасности
// * Комфорт. Новый уровень эргономики и комфорта салона
// * Гарантия — 4 года или 200 000 км`

// const t14 = `современный`


const t21 = `* ЛЁГКИЙ КОММЕРЧЕСКИЙ АВТОМОБИЛЬ НОВОГО ПОКОЛЕНИЯ.
* ВМЕСТИТЕЛЬНАЯ И УДОБНАЯ БОРТОВАЯ ПЛАТФОРМА ОТЛИЧНО ПОДХОДИТ ДЛЯ ПЕРЕВОЗКИ ЛЮБЫХ ТИПОВ ГРУЗОВ.
* ВЫСОКАЯ КОРРОЗИОННАЯ СТОЙКОСТЬ
`

const t22 = `Дизельный Cummins ISF 2.8
Модернизированный EVOTECH 
`

const t31 = `* РЕГУЛИРУЕМАЯ ПНЕВМОПОДВЕСКА
* УСИЛЕННАЯ РАМА И БОЛЬШАЯ ГРУЗОПОДЪЕМНОСТЬ
* МОЩНЫЙ КУЗОВ
* ВМЕСТИТЕЛЬНАЯ НАКОПИТЕЛЬНАЯ ПЛОЩАДКА
* УДОБНЫЕ СИДЕНЬЯ
* ВЫСОКИЙ И ПРОСТОРНЫЙ САЛОН
* ШИРОКИЙ ДВЕРНОЙ ПРОЕМ
* НИЗКИЙ ПОЛ
`
const t32 = `Дизельный Cummins ISF 2.8`

const t41 = `* ПРОДУМАННАЯ ГРУЗОВАЯ ПЛАТФОРМА
* ПЛАТФОРМА С ТРЕХСТОРОННЕЙ ЗАГРУЗКОЙ
* ШТАМПОВАННЫЕ КРОНШТЕЙНЫ СЛОЖНОГО ПРОФИЛЯ
* КРЕПКАЯ РАМА ОТКРЫТОГО ПРОФИЛЯ
* ЗАВИСИМАЯ РЕССОРНАЯ ПОДВЕСКА
* НЕЗАВИСИМАЯ ДВУХРЫЧАЖНАЯ ПЕРЕДНЯЯ ПОДВЕСКА
`
const t42 = `Дизельный Cummins ISF 2.8
Модернизированный EVOTECH
`

const t51 = `* НОВАЯ 6 МКПП
* ГРУЗОПОДЪЕМНОСТЬ 8.7т 
* БЕСКАПОТНАЯ КОМПОНОВКА
* УВЕЛИЧЕННЫЙ ХОД ПОДВЕСКИ
* ТОПЛИВНЫЙ БАК 125л.
* СОВРЕМЕННАЯ ЭРГОНОМИЧНАЯ КАБИНА
* НОВАЯ СВЕТОТЕХНИКА
* ЛЕГКОЕ УПРАВЛЕНИЕ
* ЕДИНЫЙ КЛЮЧ
`
const t52 = `Дизельный Cummins ISF 2.8`

const t61 = `* ВЫСОКАЯ КОРРОЗИОННАЯ СТОЙКОСТЬ
* СОВРЕМЕННАЯ ПОДВЕСКА
* РУЛЕВОЙ МЕХАНИЗН ZF
* МОДИФИКАЦИИ С НИЗКОЙ ПОГРУЗОЧНОЙ ВЫСОТОЙ И ВЫСОКИМ КЛИРЕНСОМ
* СОЛИДНЫЙ ЗАПАС ПРОЧНОСТИ И ТЕРПИМОСТЬ К ПЕРЕГРУЗКАМ
* ГРУЗОПОДЪЕМНОСТЬ АВТОМОБИЛЯ ПРИ ЭТОМ СОСТАВЛЯЕТ ДО 6,2 Т.
* НОВАЯ БОРТОВАЯ ПЛАТФОРМА
* АКТИВНАЯ И ПАССИВНАЯ БЕЗОПАСНОСТЬ
* ДИСТАНЦИОННЫЙ ПРИВОД КПП
* УДОБСТВО И ПРОСТОТА ОБСЛУЖИВАНИЯ
`
const t62 = `Двигатель ЯМЗ`

const t71 = `* ПРОФИЛЬНАЯ ПРОХОДИМОСТЬ
* ВЫСОКИЙ ДОРОЖНЫЙ ПРОСВЕТ
* ПОДКЛЮЧАЕМЫЙ ПОЛНЫЙ ПРИВОД
* БОЛЬШОЙ ХОД ПОДВЕСКИ
* БЛОКИРУЕМЫЙ ДИФФЕРЕНЦИАЛ ЗАДНЕГО МОСТА
* ЗАДНИЙ ПРИВОД ПРИ ОТКЛЮЧЕННОМ ПЕРЕДНЕМ МОСТЕ
* РАДИУС РАЗВОРОТА 6,5 М
* ГЛУБИНА БРОДА 500 ММ
* ВНЕДОРОЖНЫЕ ШИНЫ 225/75R16
* РУЛЕВОЕ УПРАВЛЕНИЕ С ГУР ZF
* ТРАВМОБЕЗОПАСНАЯ РУЛЕВАЯ КОЛОНКА И РУЛЕВОЕ КОЛЕСО
* ПЛОЩАДЬ ЛОБОВОГО СТЕКЛА – 1,5 КВАДРАТНЫХ МЕТРА
`
const t72 = `Дизельный Cummins ISF 2.8
Модернизированный EVOTECH
`

const t81 = `* УСИЛЕННАЯ РАЗДАТОЧНАЯ КОРОБКА PART-TIME
* РЕЖИМ ОТБОРА МОЩНОСТИ
* ПОНИЖАЮЩАЯ ПЕРЕДАЧА
* ПОДКАЧКА ВОЗДУХА НА ПАНЕЛИ ПРИБОРОВ
* САМОБЛОКИРУЮЩИЙ ДИФФЕРЕНЦИАЛ
* ПОЛНОСТЬЮ ПНЕВМАТИЧЕСКАЯ ТОРМОЗНАЯ СИСТЕМА
* КЛИМАТИЧЕСКАЯ УСТАНОВКА
`
const t82 = `Двигатель ЯМЗ`


// const t23 = `* Эффективность. Новая 6-ступенчатая МКПП'
// * Безопасность. Более 20 современных систем безопасности
// * Комфорт. Новый уровень эргономики и комфорта салона
// * Гарантия — 4 года или 200 000 км`

// const t24 = `современный`

const gimg1 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/71ff5619a0374408a564116d22500b1b/gazelle-nn-bort.jpg?width=630&thumbnail_format=webp&hmac=c6e9eff9d5295b2096ee3cf47c4d54b7f02f19a0&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/1078ef16675b47ff8e095e419bca659d/gazelle-nn-cmf.jpg?width=630&thumbnail_format=webp&hmac=67ab1d2f7d083b28c7a9f52c617f7455ca0db52c&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/ae2d168909f6426b96afbee81d866620/gazelle-nn-combi.jpg?width=630&thumbnail_format=webp&hmac=081cac2404693278de255d065e673f28f83c9b8b&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/a2d5ceb0bd004b6b891a1a22456bf2ce/gazelle-nn-bus.jpg?width=630&thumbnail_format=webp&hmac=100ab7c8f576bde36afa84f782d2dc3951a83218&height='
]

const gimg2 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/c1ca0d2657b3400a9af371e2c2eb112f/2_GAZelle_bort_630x400px.jpg?width=630&thumbnail_format=webp&hmac=9e9c9a53db069835cd33452799131429c6213be1&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/3e1d12b7c80d423d872c19fb87f78ba9/1_GAZelle_bort_630x400px.jpg?width=630&thumbnail_format=webp&hmac=bbf38050e99479203b477c1b57f3ce1a6a327422&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/56fe9ceb184344ffadc2e9b38298d67a/24_GAZelle_special_630x400px.jpg?width=630&thumbnail_format=webp&hmac=2e207fbfa64717ad92a960e972cf375f71991717&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/91bccb19de904771ace9447307556c4a/7_GAZelle_cmf_3%2C5_630x400px.jpg?width=630&thumbnail_format=webp&hmac=fdfc21c5d327a36e1419e46d360996741b8d6eb8&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/3cfee8d5cb03462c99dad657ee2de057/2_GAZelle_cmf_4%2C6_630x400px.jpg?width=630&thumbnail_format=webp&hmac=3adcad81ebdfc6bcbb31c6ab3b46bfb82477369a&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/e72254a708ed40708de8b351fc250f63/23_GAZelle_special_630x400px.jpg?width=630&thumbnail_format=webp&hmac=2d6461c4a4a26d56bd58dd95a5067a1f2bde2480&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/aa562f70d1714b3a815d3060aeb49836/1_GAZelle_bus_cmf_630x400px.jpg?width=630&thumbnail_format=webp&hmac=1ede699befe95403e8e93c9449cc057f32a593ee&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/fc5d244a1c4d411b9ea0aba84a12cb00/15_GAZelle_special_630x400_newpx.jpg?width=630&thumbnail_format=webp&hmac=d9261cb84a69029ab583191ed49581e83a3c6482&height='

]

const gimg3 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/f11de14033be49ceb655082e92111fa1/01_FL_citiline_09%20%281%29.jpg?width=630&thumbnail_format=webp&hmac=77929265a7f3e20b003087976166ed8a1ca5907f&height='
]

const gimg4 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/3b29810f66404d2cbf786797c4a839c2/valdai-next.jpg?width=630&thumbnail_format=webp&hmac=ed3d7417f5b8ddb2c52f6e3fbdd7b0896ed597d9&height='
]

const gimg5 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/deaa9352d82848d4a10cee20ef58ee5e/8_GAZon_8%2C7_uni_630x400px.jpg?width=630&thumbnail_format=webp&hmac=41d29b28fe3e0e661270791adbe20ace130582ef&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/cd7ddf175579475bb26eafb8efc0f4d7/%D0%93%D0%90%D0%97%D0%9E%D0%9D%20%D0%B1%D0%B5%D0%BB%D1%8B%D0%B9.jpg?width=630&thumbnail_format=webp&hmac=db09740391c73af77d0c1525c0e076e82ad0858f&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/05c4c9c6e2264a4c994adcd1d1042a1a/3_GAZon_8%2C7_city_630x400px.jpg?width=630&thumbnail_format=webp&hmac=ab6ed62d45539b66c09c0d31d2a68883e549f405&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/8fd2e0a4b75b4fc485a2c73ac95e4400/30_GAZon_special_630x400px.jpg?width=630&thumbnail_format=webp&hmac=06c8512001010aeed7da6dab0a696ce16070cec1&height='
]

const gimg6 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/b11335c467064018a3af469855141bde/1sobol_630x400px_2row.jpg?width=630&thumbnail_format=webp&hmac=265537f0ecf26c7e7b657156d881964cc5803fd8&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/551a122f081c4dc487d267cf542b8236/sobol_cmf_4wd_630x400px_.jpg?width=630&thumbnail_format=webp&hmac=ff37c4ea16390cc1ddfa350c5881ad615da57089&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/9b42e82aaf134450a87a6faefaaf259b/sobol_van_cmf_630x400px_.jpg?width=630&thumbnail_format=webp&hmac=563aa20def8d60734146fd91a2f200a3331effce&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/740fe89157cb49c49a263559bcbdc720/7_GAZelle_business_630x400px.jpg?width=630&thumbnail_format=webp&hmac=15753befc083fbfe7878de4ecdff309c9b6ad73d&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/bdd762ef41c34e0db4655b9d7b42f072/8_GAZelle_business_cmf_4wd_630x400px.jpg?width=630&thumbnail_format=webp&hmac=419faf2224835d6a26b5c3912de1349bcccb9693&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/3d4c35c163af4f878ca487192d8b5b28/9_GAZelle_business_bus_4wd_630x400px.jpg?width=630&thumbnail_format=webp&hmac=995de074fc02bb45b753c744f26286cb8e0f6856&height='
]

const gimg7 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/362426e14c1a44ab96bcad88533b99c1/yrrg_CURGjo.jpg?width=630&thumbnail_format=webp&hmac=0a8f2d355239645760ad4db8286f2291c1db944b&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/cb19e84acb8a4e13b18e494e8f556384/01_FL_gazon_sadko_2_L2_02.jpg?width=630&thumbnail_format=webp&hmac=fadb9526798fd62f4fad5b974091860bba60f270&height='
]


const gimg8 = [
'https://fs.azgaz-dealer.ru/azgaz_ring/4587823107514d34af99bd11e82df041/1_GAZelle_business_630x400px.jpg?width=630&thumbnail_format=webp&hmac=7f6cfa76d86576c32672be8163a3415ee296f822&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/775b867af6564a6cb2efcc48b9639224/3_GAZelle_business_630x400px.jpg?width=630&thumbnail_format=webp&hmac=8b3d79a923df7a87ec5feedbc450e4137a052580&height=',
'https://fs.azgaz-dealer.ru/azgaz_ring/870e41ba0d2948afa463d0c1537683d1/4_GAZelle_business_630x400px.jpg?width=630&thumbnail_format=webp&hmac=7c0bffa9c1afffe653995e3acbcc7bdf90273c82&height='
]


const m1  = [{'name':m01, text:t11},
			 {'name':m02, text:t12}, 
			 // {'name':m03, text:t13}, 
			 // {'name':m04, text:t14}
			 // {'name':m05, text:m09}, 
			 // {'name':m06, text:m09}, 
			 // {'name':m07, text:m09}
			 ]

const m2 = [{'name':m01, text:t21},
			{'name':m02, text:t22}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m3 = [{'name':m01, text:t31},
			{'name':m02, text:t32}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m4 = [{'name':m01, text:t41},
			{'name':m02, text:t42}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m5 = [{'name':m01, text:t51},
			{'name':m02, text:t52}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m6 = [{'name':m01, text:t61},
			{'name':m02, text:t62}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m7 = [{'name':m01, text:t71},
			{'name':m02, text:t72}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const m8 = [{'name':m01, text:t81},
			{'name':m02, text:t82}, 
			// {'name':m03, text:t23}, 
			// {'name':m04, text:t24}
			 ]

const data = [{'id':'1','name':'Газель NN',		'm':m1, 'g':gimg1, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/eb68c77fabcc44ada9e9faa061f6b187/2d436c125b7eddf69ad8980fdd989b75.png?width=520&thumbnail_format=webp&hmac=12b37fda17f20a5e90f7f03af44e0a73c1afffe7&height='},
			  {'id':'2','name':'Газель NEXT',	'm':m2, 'g':gimg2, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/43212444dc3747f3b16692e539d14ca5/1_GAZelle_bus_cmf_630x400px.jpg?width=520&thumbnail_format=webp&hmac=37684f1ea0bfb40a13868ca6c43fb76a8612d248&height='},
			  {'id':'3','name':'Газель CITY',	'm':m3, 'g':gimg3, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/804bfbcac30e47fba6156a6a163b9c84/01_FL_citiline_09.jpg?width=520&thumbnail_format=webp&hmac=b2907471e2bfcba870ae71ac6109d1e5f408895c&height='},
			  {'id':'4','name':'Газель Бизнес',	'm':m8, 'g':gimg8, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/43212444dc3747f3b16692e539d14ca5/1_GAZelle_bus_cmf_630x400px.jpg?width=520&thumbnail_format=webp&hmac=37684f1ea0bfb40a13868ca6c43fb76a8612d248&height='},
			  {'id':'5','name':'Валдай NEXT',	'm':m4, 'g':gimg4, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/e873b01dfd4e4ba19b9a7d7244beec18/valdai-next.jpg?width=520&thumbnail_format=webp&hmac=c38a2a11786c71193aa281a113cf3e9823593c45&height='},
			  {'id':'6','name':'Газон NEXT',	'm':m5, 'g':gimg5, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/ef971cc29bc448b5b9e300cd8abc6ff8/1_GAZon_10_uni_630x400px.jpg?width=520&thumbnail_format=webp&hmac=a337c727e88e35e7955225ef19fa3c10c2678f7b&height='},
			  {'id':'7','name':'Соболь 4 на 4',	'm':m6, 'g':gimg6, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/0f09e26aaa3e4b2ca3fe26ae9a58e5f1/GAZ_4WD_630x400px_ew.jpg?width=260&thumbnail_format=webp&hmac=0b9e91349bcea294d2d114cb86c29b0bb2907800&height='},
			  {'id':'8','name':'Садко NEXT',	'm':m7, 'g':gimg7, 'img':'https://fs.azgaz-dealer.ru/azgaz_ring/362426e14c1a44ab96bcad88533b99c1/yrrg_CURGjo.jpg?width=630&thumbnail_format=webp&hmac=0a8f2d355239645760ad4db8286f2291c1db944b&height='}
			  ]

export default data
