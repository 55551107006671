// нормально работает history + настройка анамации для всего рендера
// https://material-ui-pickers.dev/getting-started/installation
// https://github.com/mui-org/material-ui-pickers
// нужно проверить, что с полем выбора даты !!!
// локализация https://material-ui-pickers.dev/localization/date-fns
// https://mui.com/material-ui/react-date-picker/

//import React from 'react';
import * as React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { green } from '@mui/material/colors';

// import Fade from '@mui/material/Fade';
// import Slide from '@mui/material/Slide';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

// import Button from '@mui/material/Button';

// import 'date-fns';
import ruLocale from 'date-fns/locale/ru';
// import Grid from '@mui/material/Grid';
// import DateFnsUtils from '@date-io/date-fns';
// import {
  //MuiPickersUtilsProvider, --
  // KeyboardTimePicker,
  // KeyboardDatePicker,
 // DatePicker,
// } from '@mui/lab';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MaskEdit from 'components/maskEdit.jsx';

import {fetchDs} from '../DataS';

const drawerWidth = 300; //240

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },
  mainfixmenuButton: {
        marginRight: '-12px',
        display: 'none',
  },  
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const backMain = () => {
    // History.push('/home');
    // props.history.push('/home');
    // alert("999");
    // console.log(props);
    props.history.back();
  }

  // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onClicksend = () => {
    console.log("ClickSend in service ...");
    var s = {
      action: "service",
      qname: document.getElementsByName("qname")[0].value,
      qtext: document.getElementsByName("qtext")[0].value,
      qphone: document.getElementsByName("qphone")[0].value,
      qdate: document.getElementsByName("qdate")[0].value,      
      // chperson: document.getElementsByName("checkedIperson")[0].checked,
      // chnews: document.getElementsByName("checkedInews")[0].checked,
      // itemcontext: getDs_q(),
    }
    console.log(s);
    fetchDs(s);
    // return(s);
  }

  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            Запись на сервис
          </Typography>
          
        </Toolbar>
      </AppBar>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        onClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader} />

      {/*
        <Typography paragraph  onClick={handleDrawerClose}>
        Первая страница ... акции ... но вости ... предложение дня
        </Typography>
      */}
    <form name="formQuestion" className={classes.root} noValidate autoComplete="off">
    <div className='form-all-filed'>

    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale} >
        <DatePicker
          id="date-picker-dialog"
          label="Время визита"
          inputFormat="dd/MM/yyyy"
          openTo="day"
          views={['year', 'month', 'day']}          
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} name="qdate" helperText={null}/> }
        />
    </LocalizationProvider>

    <TextField name="qname" id="outlined-basic" label="Марка автомобиля" variant="outlined" />

    <MaskEdit name="qphone"/>

    <TextField
      name="qtext"
      id="outlined-multiline-static"
      label="Желаемые работы"
      multiline
      rows={4}
      variant="outlined"
    />

    <Button variant="contained" onClick={onClicksend}>Отправить</Button>
    </div>
    </form>

      </main>
    </div>
    </Effect>
  );
}
