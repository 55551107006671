import React, {Component} from 'react';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { Button } from '@mui/material';

const About = (props) => {

const GoBack = () => {
    props.history.back();    
}

return (
    <Container maxWidth="sm" style={{paddingTop: '20px'}}>
                <Paper elevation={0} >
                    <h3>Контакты</h3>
                </Paper>   
                
                <Paper elevation={0}>  
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                {/* <Typography variant="subtitle1" component="div" sx={{fontSize : "14px"}}> */}
                    <span>Друзья, мы каждый день стараемся быть лучшими именно для Вас. 
                        Поделитесь пожалуйста впечатлением от обслуживания ...
                    </span>
                </Typography>
                <br></br>
                </Paper>   

                <Paper elevation={1}>
                    <Button sx={{backgroundColor: '#121212 !important', justifyContent: 'left'}} 
                        fullWidth={true} 
                        size="large" 
                        href="https://gaz69.net/contacts">
                            <h4>Наш сайт</h4>
                    </Button>
                </Paper>   

                <Paper elevation={1}>
                    <Button sx={{backgroundColor: '#121212 !important', justifyContent: 'left'}} 
                        fullWidth={true} 
                        size="large" 
                        href="https://vk.com/dilergaztver?from=quick_search">
                            <h3>Наш VK</h3>
                    </Button>
                </Paper>   

                <Paper elevation={1}>
                    <Button sx={{backgroundColor: '#121212 !important', justifyContent: 'left'}} 
                        fullWidth={true} size="large" href="tel:89520690069">
                            <h3>8 (952) 069-00-69</h3>
                    </Button>
                </Paper>   
                <br></br>
                <Paper elevation={0}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                    Пункт выдачи товаров: <br></br> г.Тверь, Вагжановский переулок д.14, <br></br>магазин "ГАЗ Детали Машин"
                    </Typography>
                </Paper>
                <br></br>
                <Paper elevation={1} sx={{textAlign:"center"}}>
                    <Button sx={{backgroundColor: '#121212 !important', justifyContent: 'left'}} 
                        fullWidth={true} 
                        size="large" 
                        onClick={GoBack}>
                            <h3>Вернуться к сервисам</h3>
                    </Button>
                </Paper>                   
    </Container>
);  

}

export default About;
