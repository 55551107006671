// нормально работает history + настройка анамации для всего рендера
// https://gaztverskoy.ru/
// https://www.npmjs.com/package/react-markdown
// https://www.npmjs.com/package/react-markdown#use-a-plugin
// !!! base_auto.js

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { green } from '@mui/material/colors';

// import Fade from '@mui/material/Fade';
// import Slide from '@mui/material/Slide';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

// import Button from '@mui/material/Button';

// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';

// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import customData from '../base_auto.js';

const drawerWidth = 300; //240

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  
  root_list: {
    width: '100%',
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },
  mainfixmenuButton: {
        marginRight: '-12px',
        display: 'none',
  },
  
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));

export default function PersistentDrawerLeft(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const backMain = () => {
    // History.push('/home');
    //props.history.push('/#/home/'); !!!
    // props.history.push('/#/');!!!
    props.history.back();
    // alert("999");
    // console.log(props);
  }


  let ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
  }

  // const internatlLinks = ['/#/service', '/#/action'];

  // const offset = 16;

  // console.log(customData);

  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            Каталог Авто
          </Typography>
          
        </Toolbar>
      </AppBar>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        onClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader} />

      {/*
        <Typography paragraph  onClick={handleDrawerClose}>
        Первая страница ... акции ... но вости ... предложение дня
        </Typography>
      
      <ReactMarkdown children={customData[0].m1} remarkPlugins={[remarkGfm]} />
      */}
    <List component="nav" className={classes.root_list} aria-label="contacts">

      {customData.map((item) => (
        // console.log(item.name)
      <ListItemLink style={{display:'block'}} button key={item.id} href = {"/#/autoitem/" + item.id}>
        <img src= {item.img} alt ='' style={{width:'100%'}}/>
        <ListItemText inset primary={item.name} style={{paddingLeft:'0px'}}/>
      </ListItemLink>

      ))}

    </List>

      </main>
    </div>
    </Effect>
  );
}

