// import { red } from '@mui/material/colors';
//import { createMuiTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// const themeLight = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//     background: {
//       default: '#fff',
//     },
//   },
// });

const theme = createTheme({
  palette: {
    // Switching the dark mode on is a single property value change.
    mode: 'dark', //type
  },
  
  overrides: {
    MuiOutlinedInput: {
        multiline: {
            // fontWeight: 'bold',
            // fontSize: '20px',
            // color: 'purple',
            // width: '50vw'
        }
    }
  }

});

// export default theme;
export default theme;