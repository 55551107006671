// npm i react-text-mask --save
// https://github.com/text-mask/text-mask/tree/master/react#readme

// npm install react-input-mask@next --save
// https://github.com/sanniassin/react-input-mask
// https://react.libhunt.com/react-input-mask-alternatives

import React from 'react';
// import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField';
// import { OutlinedInput } from '@mui/material';



const SaveValueToLocalStorage = (v) => {
    // let data_to_save = JSON.stringify(v);
    window.localStorage.setItem('data_gaz69_telephone', v);
}

const LoadValueFromLocalStorage = () => {
//     let newState = JSON.parse(window.localStorage.getItem('data_gaz69'));
//     this.setState(newState);
  // return (JSON.parse(window.localStorage.getItem('data_gaz69_telephone')));
  return (window.localStorage.getItem('data_gaz69_telephone'));
} 

const SetStr = (StrVal) => {
  let s = LoadValueFromLocalStorage();
  // console.log(s)
  // if(s.length > 0) {
  if ((s !== null) && (s.length > 0)) {
    // return (s);
    // return ("Телефон");
  } else {
    return (StrVal);
  }
  // return(s.length > 0 ? " " : StrValue);
}

const MyMaskedInput = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;

  // implement `InputElement` interface
//  React.useImperativeHandle(ref, () => ({
//    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
//    },
    // hiding the value e.g. react-stripe-elements
//  }));

  // `Component` will be your `SomeThirdPartyComponent` from below

  return (
    <MaskedInput
      {...other}
      // ref={ref}
      mask={['+','7','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      type="tel"
      placeholder="+7(   )    -     "
      onBlur={() => {
        console.log("onBlur");
      }}
      onChange={(v) => {
        // console.log(v.target.value);
        SaveValueToLocalStorage(v.target.value);
      }}
      // onFocus={(v) => {
        // let s = SetStr();
        // if (s.length > 4){ 
          // v.target.value = s.substring(4, v.target.length);
          // console.log("set");
        // }
        // console.log(s.substring(4));
      // }}
      // value = {LoadValueFromLocalStorage()}
      // showMask
    />
  )
});

// inputcomponent={MyMaskedInput} />

const MyInput = ({...inputProps }) => {
  // let rr = React.createRef();
  let rr = React.useRef(null);
  //{SetStr("Телефон")}
  return (
    <>
    <TextField 
      id="outlined-basic" 
      label={SetStr("Телефон")}
      color ="primary"
      variant="outlined" 
      name={inputProps.name}
      // placeholder="Enter a Phone number"
      // inputcomponent={MyMaskedInput} 
      inputRef = {rr}
      value = {LoadValueFromLocalStorage()}
      InputProps={{
            inputComponent: MyMaskedInput,
            inputRef: {rr}
          }}
      />
    </>
  )
}


export default MyInput

