// нормально работает history + настройка анамации для всего рендера
// уюрать лишнее и запилить бесконечный список (возможно состояние, для понимания структуры)
// https://habr.com/ru/post/429712/
// https://www.npmjs.com/package/react-material-ui-carousel
// https://github.com/Learus/react-material-ui-carousel
// https://material-ui.com/ru/components/skeleton/
// настройки карусели (правильные )
// https://stackoverflow.com/questions/68679983/problems-with-react-material-ui-carousel-can-not-display-three-cards-in-one-ro

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';

import { makeStyles
  // , useTheme 
} from '@mui/styles';

// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

// import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';

// import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';

// import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { green } from '@mui/material/colors';

import { useParams } from "react-router-dom";

// import Fade from '@mui/material/Fade';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

import { CircularProgress } from '@mui/material';

import Carousel from 'react-material-ui-carousel';
import { Paper} from '@mui/material';

// import { Skeleton } from '@mui/material';

import {setDs_q} from '../DataS';

import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'

const drawerWidth = 300; //240
const validImage = "medium";

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  // drawerPaper: {
  //   width: drawerWidth,
  // },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight:'25px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
    paddingTop:'30px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },

  mainfixmenuButton: {
        marginRight: '-12px',
        // display: 'none',
  },

  inline: {
    display: 'inline',
  },

  carousel__class: {
    width: '100%',
    // height: 'auto',
    height: '330px',
    // drawerWidth
  },

  item__image:{
  transform:"scale(1.35)",
  height: '275px', /*260 - 275*/
  /*height: 100%;*/
  // width: '-webkit-fill-available',
  // marginLeft: "auto",
  // marginRight: "auto",
  },

  rootlist: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },

}));

let config = dotenv.config()
// console.log(process.env)
dotenvExpand(config)

export default function PersistentDrawerLeftItem(props) {
  
  const classes = useStyles();
  // const theme = useTheme();
  let navigate = useNavigate();

  let { id } = useParams();
  // const [open, setOpen] = useState(false);
  const open = false;
  const [state, setState] = useState({});

// Route::get('/v0/group/{id}', 'ContentController@getgroupid');
// Route::get('/v0/item/{id}', 'ContentController@getitemid');
// Route::get('/v0/home', 'ContentController@gethome');

  // let config = dotenv.config()
  // console.log(process.env)
  // dotenvExpand(config)

  var api_end_pint = process.env.REACT_APP_API_END_POINT_GAZ69;
  var web_root_site = process.env.REACT_APP_WEB_ROOT_SITE;
  
  const loadDataAPI = (ids) => {
    // api_end_pint + "group/"+ids"
    // fetch("http://api.gaz69.net/api/v0/item/"+ids)
    fetch(api_end_pint+"item/"+ids, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      setState(data);
    });

    // console.log(api_end_pint+"group/"+ids);
  }

  useEffect (()=> {
    console.log("fetching item..."+id);
    loadDataAPI(id);        
    // console.log("#"+web_root_site);    
  }, [ id ]);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const isEmpty = function (obj) {
      for(var key in obj)
      {
          return false;
      }
      return true;
  }

  const backMain = () => {
    // History.push('/home');
    // props.history.push('/home');
    props.history.back();
    // alert("999");
    // console.log(props);
  }

  const checkMediumImage = function (obj, validImage) {
    // var k = obj.path.split("/"); //k[1]
    var ss = obj.url.split("/");
    if (ss[5] === validImage && obj["active"] === 1) {
      return true;
    }
    return false;
  }

  // объединение одинаковых позиций по корзине (тут надо срочно доделывать)
  const concantItemInCart = (cart) => {
    // console.log("@");
    // console.log(cart);
    const newcart = cart.reduce((acc, element) => { 

      var s = acc.findIndex((item) => item.id === element.id);
      s !== 0 ? acc.push(element) : acc[s].count += 1;
      return acc;

    }, []);
    // var newcart = cart;
    // console.log(newcart);
    return (newcart);
  }
  //

  // const Add = props.state.addToCart;

  const Add = function (p) {
    // в Р весь полный item
    p['count'] = 1;
    // console.log(p);
    // var { count, CartItems } = props.pstate;
    var { CartItems } = props.pstate;
    // count += 1;
    CartItems.push(p);
    //объединяем позиции в корзине
    const NCartItems = concantItemInCart(CartItems);
    const Ncount = NCartItems.length;
    props.onSet({...props.pstate, count:Ncount, CartItems:NCartItems});
    // var k = props.onSet(props.pstate.addToCart(p));
  }

  //

  const ItemCarusel = function (props) {
      // console.log(props);
      let kk = props.item.filter( (it) => ( checkMediumImage(it, validImage) ));

      if (kk.length === 0) {
        // props.nullImg
        // img = web_root_site+props.nullImg
        // REACT_APP_NULL_IMAGE
        // "/assets/components/minishop2/img/web/ms2-small.png"
        // "/assets/images/products1/11074/medium/04368,1.JPG"
        // вставляем пустую картинку
        kk.push({
          product_id: null, 
          path: '', 
          url: process.env.REACT_APP_NULL_IMAGE, 
          file: '04368.JPG', 
          properties: null,
          active: 1
        })
      }

      // console.log(kk);
      //  <Carousel fullHeightHover = {false} autoPlay = {false} navButtonsAlwaysVisible = {true} navButtonsAlwaysInvisible = {true}
      return (
          <Carousel className={classes.carousel__class} autoPlay = {false} animation = "slide"
              indicatorIconButtonProps={{
                  style: {
                      padding: '1px',    // 1
                      // color: 'blue',       // 3
                      zIndex: 'inherit',
                  }
              }}
              activeIndicatorIconButtonProps={{
                  style: {
                      // backgroundColor: 'red' // 2
                  }
              }}
              indicatorContainerProps={{
                  style: {
                      marginTop: '0px', // 5 -15 fdn
                      zIndex: '1',
                      // textAlign: 'right' // 4
                  }
              }}
          >
              {
                  kk.map(  (item, index) => {
                    // checkMediumImage(item, validImage) == true &&
                    //   <Item key={index} item={item} />
                      // if (checkMediumImage(item, validImage) === true) {
                        // console.log(index);
                        return(<Item key={index} item={item} />)
                      // } 
                    }
                  )
              }
          </Carousel>
      )
  }

  //

  const Item = function (props) {

      // var img = new Image();
      // img.src = props.item.url;

      // console.log(props);

      return (
          <Paper className="item__first__img" style={{width: "100%"}} >
                <img className={classes.item__image} src={web_root_site + props.item.url} alt="" style={{width: "auto"}}/>
          </Paper>
      )
  }

  //

//   const FirstImage = function (props) {
//   let kk = props.item.filter( (it) => ( checkMediumImage(it, validImage) ));

//   let item_url = kk.length === 0?'/assets/components/minishop2/img/web/ms2-small.png':kk[0].url;

//   console.log(props);

//   return (
//       <Paper elevation={0} className="item__first__img" style={{width: "100%"}}>
//         <img src={web_root_site + item_url} className="item__image" alt="" style={{width: "100%"}}/>
//       </Paper>
//   )
// }

//

// const LastImage = function (props) {

//   console.log(props);

//   let kl = props.item.filter( (it) => ( checkMediumImage(it, validImage) )).
//     filter((it, index) => (index > 0));

//   return (
//       <Paper elevation={0} className="item__last__img">
//         { kl.length > 0 &&
//         <img src={web_root_site + kl[0].url} className="item__image" alt="" style={{width: "100%"}}/>
//         }
//       </Paper>
//   )
// }

const GoQoestion = function (p) {
  let dataOut = {action:'godsitem', ...p}; 
  console.log(dataOut);
  console.log("clisk button question");
  setDs_q(dataOut);
  // props.history.push('/question');
  navigate("/question");
}

  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            gaz69.net
          </Typography>
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            href = "/#/cart"
            className={clsx(classes.mainfixmenuButton, open && classes.hide)}
          >
            
            { props.pstate.count === 0 ? <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart/> </Badge>: <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart style={{ color: green[500] }}/> </Badge>}
          
          </IconButton>

        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{paddingLeft:"0px", paddingRight: "0px"}}
      >
        <div className={classes.drawerHeader} />
        {/*
        <div>
          <h3>ID: {id}</h3>
        </div>
        */}
        { isEmpty(state.data) === false &&
          
          <ListItem component="div" style={{paddingLeft:"0px", paddingRight:"0px", flexDirection: "column", alignItems: "center", whiteSpace: "pre-line"}}>
            {/*
            <FirstImage item={state.data.images} />
            */}
            <ItemCarusel item={state.data.images}/>

            <div className="item__button_div" style={{textAlign: "center", width: "inherit"}}>
            <Button variant="contained" size="small" onClick={() => GoQoestion(state.data.item[0])} style={{margin:"15px"}}>
              ЗАДАТЬ ВОПРОС
            </Button>
            <Button variant="contained" size="small" onClick={() => Add(state.data.item[0])} style={{margin:"15px"}}>
              В КОЗИНУ
            </Button>            
            </div>

            <div className = "item__info_div">
            
            <ListItemText primary={state.data.item[0].pagetitle}/>            
            <ListItemText primary={"Цена: " + state.data.item[0].price}/>
            
            <ul style={{paddingLeft:"15px", marginTop: "4px"}}>
              <li>{"Заводской номер: "+ state.data.item[0].article}</li>
              <li>{"Код внутренний: "+ state.data.item[0].id}</li>
              <li>{"В наличии: " + state.data.item[0].remaind+" шт."}</li>
            </ul>

            </div>
            {/*
             <ListItemText secondary={"Заводской номер: "+ state.data.item[0].article}/>
             <ListItemText secondary={"Код внутренний: "+ state.data.item[0].id}/>
             <ListItemText secondary={"В наличии:" + state.data.item[0].remaind+" шт."}/>
            */} 
            {/*
            <LastImage item={state.data.images} />
            */}
          </ListItem> 

        }

        { isEmpty(state.data) === true &&
            
          <div style={{textAlign: "center",  marginTop: "150px"}}>
            <CircularProgress />
          </div>

        }

      </main>
    </div>
    </Effect>
  );
}
