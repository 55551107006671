// задать вопрос в контексте
// textArea component
// https://github.com/nicolaslopezj/simple-react-form-material-ui/blob/master/src/textarea.jsx

import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { green } from '@mui/material/colors';

import {Slide as Effect} from '@mui/material';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';


//maskInput
// import PropTypes from 'prop-types';
// import MaskedInput from 'react-text-mask';
// import Input from '@mui/material/Input';

// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
//

// import TextareaAutosize from '@mui/material/TextareaAutosize';

import MaskEdit from 'components/maskEdit.jsx';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {fetchDs, getDs_q} from '../DataS';

// import Shop from './shop.jsx';

const drawerWidth = 300; //240

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },
  mainfixmenuButton: {
        marginRight: '-12px',
        display: 'none',
  },  
}));

export default function PersistentDrawerLeft(props) {
  
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const backMain = () => {
    // History.push('/home');
    // props.history.push('/home');
    // props.history.goBack();
    props.history.back();
    // alert("999");
    // console.log(props);
  };


  // const internatlLinks = ['/#/service', '/#/action'];

  // const offset = 16;

  const onClicksend = () => {
    console.log("...ClickSend ");
    let data = getDs_q();
    let {action} =  data;
    let s = {
      qname: document.getElementsByName("qname")[0].value,
      qphone: document.getElementsByName("qphone")[0].value,
      qtext: document.getElementsByName("qtext")[0].value,
      chperson: document.getElementsByName("checkedIperson")[0].checked,
      chnews: document.getElementsByName("checkedInews")[0].checked,
      // itemcontext: getDs_q(),
      itemcontext: data,
      action:action,
    }
    console.log(s);
    fetchDs(s);
    // return(s);
  }

  return (
    <Effect direction="down" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
            {/*
            <MenuIcon />
            */}
          </IconButton>

          <Typography variant="h6" noWrap>
            Тверь
          </Typography>
          {/*
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            className={clsx(classes.mainfixmenuButton, open && classes.hide)}
          >
            
            { 1 == 1 ? <ShoppingCart/> : <ShoppingCart style={{ color: green[500] }}/> }
          
          </IconButton>
          */}
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
      {/*
        <List>
          {['Автомобили'].map((text, index) => (
            <ListItemLink key={text} href = {"/#/auto/"}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemLink>
          ))}
        </List>
        <Divider />
        <List>
          {['Запчасти ГАЗ', 'Запчасти УАЗ', 'Паз'].map((text, index) => (
            <ListItemLink key={text} href = {"/#/shop/" + Number(index+offset)}>
              <ListItemIcon>{(Number(index+offset)) % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemLink >
          ))}
        </List>
        <Divider />
        <List>
          {['Сервис', 'Акции'].map((text, index) => (
            <ListItemLink button key={text} href = {internatlLinks[index]}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemLink>
          ))}
        </List>
        */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        onClick={handleDrawerClose}
      >

        <div className={classes.drawerHeader} />

      {/*
        <Typography paragraph  onClick={handleDrawerClose}>
        Первая страница ... акции ... но вости ... предложение дня
        </Typography>


        <div className = "main-logo">
          <img src = "./img/logo.png" alt = ""/>
        </div>
      */}

    <form name="formQuestion" className={classes.root} noValidate autoComplete="off">
      <div className='form-all-filed'>

      <TextField name="qname" id="outlined-basic" label="Имя \ Организация " variant="outlined" />
{/*      
      <TextField id="outlined-basic" label="Телефон" variant="outlined" />
*/}
{/*
      <MaskedInput
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      className="form-control"
      placeholder="Enter a phone number"
      guide={false}
      id="my-input-id"
      onBlur={() => {}}
      onChange={() => {}}
      />
*/}

{/*
      <TextareaAutosize
        maxRows={4}
        aria-label="maximum height"
        placeholder="Maximum 4 rows"
      />
*/}

      <MaskEdit name="qphone"/>

      <TextField
        name="qtext"
        id="outlined-multiline-static"
        label="Комментарии"
        multiline
        rows={4}
        variant="outlined"
      />


      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="checkedIperson"
          />
        }
        label="Согласие на обработку персональных данных"
      />

      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="checkedInews"
          />
        }
        label="Согласие на получение информации"
      />

      <Button variant="contained" onClick={onClicksend}>Отправить</Button>

      </div>
    </form>


      </main>
    </div>
    </Effect>
  );
}
