// нормально работает history + настройка анамации для всего рендера
// maskEdit
// https://codesandbox.io/s/4es23?file=/src/CurrencyInput.js
// https://www.npmjs.com/package/react-text-mask

import React from 'react';
import clsx from 'clsx';
import { makeStyles
  // , useTheme 
} from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import { green } from '@mui/material/colors';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';


// import Fade from '@mui/material/Fade';
// import Slide from '@mui/material/Slide';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

import MaskEdit from 'components/maskEdit.jsx';

import { useNavigate, useLocation } from "react-router-dom";

import {fetchDs
  // , setDs_q, getDs_q
} from '../DataS';

// import { DataGrid } from '@material-ui/data-grid';
//import { initState as EmptyState } from '../rootState.js';
// import { XGrid } from '@material-ui/x-grid';
// import { useDemoData } from '@material-ui/x-grid-data-generator';

import '../app.css';

const drawerWidth = 300; //240

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight:'40px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },
  mainfixmenuButton: {
        marginRight: '-12px',
        display: 'none',
  },  
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  // const theme = useTheme();


  // const [open, setOpen] = React.useState(false);
  const open = false;

  // var api_end_pint = process.env.REACT_APP_API_END_POINT_GAZ69;
  var web_root_site = process.env.REACT_APP_WEB_ROOT_SITE;
  var null_image = process.env.REACT_APP_NULL_IMAGE;

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const backMain = () => {
    // History.push('/home');
    //props.history.push('/home');
    props.history.back();
    // alert("999");
    // console.log(props);
  }
  
  // запустить оплату
  const clickPay = () => {
    //  Добавить телефон
    var data = props.pstate;
    // data.phone = document.getElementsByName("qphone")[0].value;
    data.phone = window.localStorage.getItem('data_gaz69_telephone');
    data.action = 'cart';
    var rez = fetchDs(data);
    // if (rez === 'OK') {
    //     navigate('/ok');
    // }
    console.log(data);
  }

  // запустить очистку всего чека 
  const clickClear = () => {
    // var { count } = props.pstate;
    // count +=1;
    // CartItems.push(p);
    props.onSet({...props.pstate, count:0, CartItems:[]});
    // props.onSet({...props.pstate, count:count, CartItems:CartItems});
    // console.log(props);
  }

  //удалить одну позицию
  const clickDelPosition = (s) => {
    // var { count, CartItems } = props.pstate;
    // count -=1;
    // console.log(s.target.getAttribute('datekey'));
    // console.log(s.target.getAttribute('datekey')>0?'+':'-');
    if (s.target.getAttribute('datekey')>0) {
      var { count, CartItems } = props.pstate;
      count -=1;
      const NCartItems = CartItems.filter((item, index) => index !== (s.target.getAttribute('datekey') - 1));
      props.onSet({...props.pstate, count:count, CartItems:NCartItems});
    }
    // const NCartItems = CartItems.filter((item, index) => index != (s.target.getAttribute('datekey') - 1));
    // props.onSet({...props.pstate, count:count, CartItems:NCartItems});
    // console.log(has);
  }

  // добавить +1 в позицию
  const clickAddPosition = (s) => {
    // console.log(s);
    if (s.target.getAttribute('datekey') > 0) {
      var { count, CartItems } = props.pstate;
      // count -=1;
        CartItems[(s.target.getAttribute('datekey') - 1)].count += 1; 
        props.onSet({...props.pstate, count:count, CartItems:CartItems});
      }
  }
  // удалить -1 из позиции
  const clickRemovePosition = (s) => {
    // console.log(s);
    // console.log(s.target.getAttribute('datekey'));
    if (s.target.getAttribute('datekey') > 0) {
      var { count, CartItems } = props.pstate;
      // count -=1;
        if (CartItems[(s.target.getAttribute('datekey') - 1)].count >0) {
          CartItems[(s.target.getAttribute('datekey') - 1)].count -= 1; 
          props.onSet({...props.pstate, count:count, CartItems:CartItems});
        }
      }  
  }
  //
  const cropName = (s) => {
    return (s.length > 60 ? s.substring(0,60)+"...": s);
  }
  //
  const DefGridRow = (props) => {
    var index = Number(props.indexs);
    index = index + 1;
    var img = props.item.image;
    (props.item.image == null) ? (img = web_root_site+props.nullImg) : img = web_root_site+props.item.image;
    console.log(props.item.image);
    console.log(img);
  //return(<tr><td>{index}</td><td>{props.item.pagetitle}</td><td>{props.item.price}</td><td><span datekey={index} onClick={props.onClick}>Del</span></td></tr>);
  return(<tr>
  <td>
            <ListItemAvatar>
              <Avatar style={{backgroundColor: "white"}}>
                <img src={img} alt='' className="list__items__image"/>
              </Avatar>
            </ListItemAvatar> 
  </td>
  <td>{cropName(props.item.pagetitle)}
    <div className='bill-price'>
      <div className='bill-price-price'> {props.item.price} руб.</div>
      <div className='bill-price-action'>
        <div className='bill-price-remove'><IconButton aria-label="open drawer" datekey={index} onClick={props.onClickRemove}><RemoveCircleIcon datekey={index} /> </IconButton></div>
        <div className='bill-price-remaind'>{props.item.count}</div>
        <div className='bill-price-add'><IconButton aria-label="open drawer" datekey={index} onClick={props.onClickAdd}><AddCircleIcon datekey={index} /> </IconButton></div> 
        <div className='bill-item-delete'><IconButton aria-label="open drawer" datekey={index} onClick={props.onClickDel}><ClearIcon datekey={index} /> </IconButton></div> 
      </div>
    </div>
  </td>

  </tr>);
}

const DefGrid = () => {
  const items = props.pstate.CartItems;
  const count = props.pstate.count;
  return (
    <table className="bill--table">
    {/*
    <thead>
      <tr>
        <th>ID</th>
        <th>Товар</th>
        <th>Цена</th>
        <th>Action</th>
      </tr>
      </thead>
    */}  
      <tbody>
      { count > 0 &&
        items.map((item, index) => {
          return(<DefGridRow key={index} indexs={index} item={item} nullImg={null_image} onClickDel={clickDelPosition} onClickAdd={clickAddPosition} onClickRemove={clickRemovePosition}/>)
        })
      }
      {
        count === 0 &&
        <tr className='cart-empty'><td colSpan="4" style={{borderBottom: "none"}}>Нет товаров в корзине</td></tr>
      }
      </tbody>
    </table>
  )
}

const BSend = () => {
  const count = props.pstate.count;
  // https://material-ui.com/components/bottom-navigation/#bottom-navigation
  // https://www.figma.com/file/osjAoxwzhNh1eRjML0X9Dy/%D0%93%D0%90%D0%9769?node-id=0%3A1
  
  if (count > 0) { 
  return (
    <BottomNavigation>

  {/*
    <div style = {{padding:'15px', width:'100%', textAlign:'center'}}>
      <Button variant="outlined" style={{margin:'10px'}} onClick={clickPay}>Оформить заказ</Button>
      <Button variant="outlined" color="primary" style={{margin:'10px'}} onClick={clickClear}>Очистить</Button>
    </div>
  */}
      <BottomNavigationAction onClick={clickPay} showLabel={true} label="Оформить" value="recents" />
      <BottomNavigationAction onClick={clickClear} showLabel={true} label="Очистить" value="favorites" />
    
    </BottomNavigation>
  )
  } else {
  return(
    <div style = {{padding:'15px', width:'100%', textAlign:'center'}}>
      {/*
      <span onClick={backMain}>Продолжить покупки</span>
      */}
      <Button variant="outlined" style={{margin:'10px'}} onClick={backMain}>Продолжить покупки</Button>
    </div>
  )
  }
}

// дополнительное предложение (исходя из того, что покупает клиент, даем рекомендации)
// const PerosnData = () => {
//   console.log(props.pstate.CartItems);
//   return(
//     <h5>Предполагаем дополнительные товары</h5>
//     )
// }

//

const SummCart = (cart) => {
  const ressm = cart.reduce((acc, element) => 
      parseFloat(acc) + (parseFloat(element.count) * parseFloat(element.price))
  ,0);
  return (new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(ressm));
}

//

const Cartfooter = () => {
  
  const items = props.pstate.CartItems;
  const count = props.pstate.count;
  
  return (
    <div className='cart-bottom'>
      
      <div>
        <Typography noWrap>
        Товаров: <br/> {count} шт.
        </Typography>
      </div>

      <div>
        <Typography noWrap>
        Итого: <br/>{SummCart(items)}
        </Typography>
      </div>
    
    </div>
  )
}

//            { 1 == 1 ? <ShoppingCart/> : <ShoppingCart style={{ color: green[500] }}/> }
  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            Корзина
          </Typography>
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            className={clsx(classes.mainfixmenuButton, open && classes.hide)}
          >
            

{ props.pstate.count === 0 ? <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart/> </Badge>: <Badge badgeContent={props.pstate.count} color="primary"> <ShoppingCart style={{ color: green[500] }}/> </Badge>}            

          
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        },"main--cart")}
      >
        

        <div className={classes.drawerHeader} />
        
        {/*
        <Typography paragraph onClick={handleDrawerClose}>
        В корзине
        </Typography>
        */}

        <DefGrid/>

        {props.pstate.count > 0 ? <div> <Cartfooter/> <div className='cart-contact'> <MaskEdit name="qphone"/> </div> </div>: <div></div>}

        <BSend/>

      {/*
        <PerosnData/>
      */}

      </main>
    </div>
    </Effect>
  );
}
