// нормально работает history + настройка анамации для всего рендера
// https://gaztverskoy.ru/
// https://www.npmjs.com/package/react-markdown
// https://www.npmjs.com/package/react-markdown#use-a-plugin
// !!! base_auto.js
// нужно загрузить галерею попробовать сделать через склиетон

import React from 'react';
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles
  // , useTheme 
} from '@mui/styles';
// import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import ListSubheader from '@mui/material/ListSubheader';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ShoppingCart  from '@mui/icons-material/ShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { green } from '@mui/material/colors';

// import Fade from '@mui/material/Fade';
// import Slide from '@mui/material/Slide';
// import Collapse from '@mui/material/Collapse';
// import Zoom from '@mui/material/Zoom';
import {Slide as Effect} from '@mui/material';

import Button from '@mui/material/Button';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useParams } from "react-router-dom";

import customData from '../base_auto.js';

import Carousel from 'react-material-ui-carousel';
import { Paper} from '@mui/material';

import {setDs_q} from '../DataS'

const drawerWidth = 300; //240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  
  root_list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mainfix: {
    justifyContent: 'space-between',
  },

  carousel__class: {
    width: '100%',
    height: '250px',
    // drawerWidth
  },

  item__image__auto:{
    /*transform:scale(1.35);*/
    height: '200px',
    width: '-webkit-fill-available',
  },

  mainfixmenuButton: {
        marginRight: '-12px',
        display: 'none',
  },
  
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

}));

export default function PersistentDrawerLeft(props) {

  let { id } = useParams();
  let navigate = useNavigate();

  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {

    console.log("handleDrawerClose ");
    // console.log(this);
    setOpen(false);
  };

  const backMain = () => {
    // History.push('/home');
    // props.history.push('/home');
    // props.history.goBack();
    props.history.back();
    // alert("999");
    // console.log(props);
  }

  const GoQoestion = function (p) {
    // let dataOut = {action:'autoitem', ...p};
    // не передаются массивы с URL 
    // видимо нужно кодировать эти тексты (что бы можно было передать)
    let {name} = p; 
    let dataOut = {action:'autoitem', name:name};
    console.log(dataOut);
    console.log("clisk button question item auto");
    setDs_q(dataOut);
    // props.history.push('/question');
    navigate("/question");
  }

  //

  const ItemCarusel = function (props) {
      // let kk = props.item.filter( (it) => ( checkMediumImage(it, validImage) ));
      // console.log(kk);
      // console.log(props);
      return (
          <Carousel className={classes.carousel__class} autoPlay = {false} animation = "slide">
              {
                  props.item.map(  (item, index) => 
                    // checkMediumImage(item, validImage) == true &&
                      {
                        // console.log (item);
                        return (<Item key={index} item={item} />);
                      }
                      
                  )
              }
          </Carousel>
      )
  }

  //

  const Item = function (props) {

      // var img = new Image();
      // img.src = props.item.url;
      // console.log(props);
      return (
          <Paper className="item__first__img">
                <img className={classes.item__image__auto} src={props.item} alt="" style={{width: "100%"}}/>
          </Paper>
      )
  }

  //


  let ItemRow = (props) => {
    // првоерить на props.item.URL
    // console.log("auto detail ...", id);
    let srcItem = customData.filter( item => item.id === id)

    console.log(srcItem[0]);
//       <Accordion key={index} expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
//  FDN в противном случе происходит перерисовка всего компонента (что не правильно)
    return(
      <div>
      <Typography className={classes.heading}>{srcItem[0].name}</Typography>
      
      <hr />
      <ItemCarusel item={srcItem[0].g}/>
      <hr />

      {srcItem[0].m.map((item,index) => (

      <Accordion key={index}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{item.name}</Typography>
          {/*<Typography className={classes.secondaryHeading}>I am an accordion</Typography>*/}
        </AccordionSummary>
        <AccordionDetails style={{ display: "block"}}>
          {/*
          <Typography>
            {item.text}
          </Typography>
          */}
          <ReactMarkdown children={item.text} remarkPlugins={[remarkGfm]} />
        </AccordionDetails>        
      </Accordion>   
      
      ))}

    {/*кнопка задать вопрос - GoQoestion(state.data.item[0]) */}
        <div className="item__button_div main-buttons" style={{textAlign: "center", width: "inherit"}}>
           <Button variant="contained" size="large" onClick={() => GoQoestion(srcItem[0])} style={{margin:"15px", width:"100%"}}>
            ЗАДАТЬ ВОПРОС
           </Button>

{/*
        <Button variant="contained" size="small" color="default" onClick={() => GoQoestion(0)} style={{margin:"15px", width:"100%"}}>
          ЗАДАТЬ ВОПРОС
        </Button>
*/}
        </div>
      </div>
    )
  }



  //const internatlLinks = ['/#/service', '/#/action'];

  // const offset = 16;

  // console.log(customData);

  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   console.log(panel);
  //   // setExpanded(isExpanded ? panel : false); 
  //   //  FDN
  // };

  return (
    <Effect direction="left" in = {true} mountOnEnter unmountOnExit>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color = "transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.mainfix}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={backMain}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            Каталог Авто
          </Typography>
          
        </Toolbar>
      </AppBar>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        onClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader} />

      {/*
        <Typography paragraph  onClick={handleDrawerClose}>
        Первая страница ... акции ... но вости ... предложение дня
        </Typography>
      
      <ReactMarkdown children={customData[0].m1} remarkPlugins={[remarkGfm]} />
      */}

      <ItemRow />

      </main>
    </div>
    </Effect>
  );
}

