import React, {Component} from 'react';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';

class OkFill extends Component {

    constructor (props) {
    	
		super(props);

		setTimeout(() => {

    		props.history.back();

		},3500);

    }
     render () {

      return (
      	<Container maxWidth="sm" style={{paddingTop: '20px'}}>
			<Typography variant="h5" component="h6" align="center">
				Спасибо, заявка\зпррос отправлен(а)
			</Typography>
			<Typography variant="h6" component="h6" align="center">
				 <p>
      				в ближайшее время с Вами свяжется наш специалист ...
    			</p>
			</Typography>
		</Container>
      );  

    }
}

export default OkFill
